import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required."
const VALID_EMAIL_MESSAGE = "Please type a valid email."

export const authValidationSchema = (module) => {
  let validationSchema = {
    email: Yup.string().email(VALID_EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
  }

  if (module === "sign-in")
    validationSchema.password = Yup.string().required(REQUIRED_MESSAGE)
  else if (module === "sign-up") {
    validationSchema.password = Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(7, "Your password must be at least seven characters long.")
      .max(80, "Your password must not exceed 80 characters in length.")
      .matches(
        /(?=.*[a-z])/,
        "Your password must contain at least one lowercase character."
      )
      .matches(
        /(?=.*[A-Z])/,
        "Your password must contain at least one uppercase character."
      )
      .matches(/(?=.*[0-9])/, "Your password must contain at least one number.")
      .matches(
        /(?=.*[!|@|#|$|%|&|_|+|=|.|-])/,
        "Your password must contain at least one of these special characters: !@#$%&_-+=."
      )
      .matches(
        /^[a-zA-Z0-9!@#$%&_+=.-]{7,}$/,
        "Your password contains an invalid special character. Please use any from the following only: !@#$%&_-+=."
      )
    validationSchema.confirmPassword = Yup.string()
      .required(REQUIRED_MESSAGE)
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  }

  return Yup.object().shape(validationSchema)
}
