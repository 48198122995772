import React from "react"
import firebase from "firebase"
import { navigate, Link } from "gatsby"
import { handleSignOut } from "./signout"
import { isBrowser } from "services/general"
import VerifyEmailModal from "../VerifyEmailModal"

import { GATSBY_ENV } from "gatsby-env-variables"
import { getUserEnrollment } from "./user"

export const getUserData = async ({ authUid }) => {
  const filteredUsers = await firebase
    .firestore()
    .collection("users")
    .where("authUid", "==", authUid)
    .get()
  let userData

  filteredUsers.forEach((user) => {
    userData = { sex: user?.gender || "", ...user.data(), id: user.id }
  })

  return userData
}

export const getUserAddresses = async ({ addressesId }) => {
  let filteredAddress = await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesId || " ")
    .get()
  if (filteredAddress.data())
    filteredAddress = {
      ...filteredAddress.data(),
      id: filteredAddress.id,
    }
  else filteredAddress = {}

  return filteredAddress
}

export const handleEmailLogin = async ({
  values,
  callBack,
  setMessage,
  resetForm,
  errorCallback,
  dispatch,
  setLoading,
  location,
}) => {
  let email = values?.email
  let password = values?.password

  try {
    // if (!!user?.authUid) {
    // Sign in user
    let response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    // Handle if emil is verified through email verification
    const isVerified =
      GATSBY_ENV === "production" ? response?.user?.emailVerified : true

    if (isVerified) {
      const authUid = response?.user?.uid

      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      let userData = (await getUserData({ authUid })) || {}
      let addressesId = userData?.addresses
      let userAddresses = await getUserAddresses({ addressesId })
      let enrollment = (await getUserEnrollment({ userData })) || {}

      // Save user data and addresses to session
      if (isBrowser()) {
        sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
        sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
        sessionStorage.setItem("enrollment", JSON.stringify(enrollment))
      }

      navigate("/")
    } else {
      // If not verified, prevent login and show modal
      await handleSignOut({ redirect: false })
      setLoading(false)
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Your email is not verified",
          isCard: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
          content: (
            <VerifyEmailModal user={response?.user} location={location} />
          ),
        },
      })
      return
    }
  } catch (error) {
    if (errorCallback) errorCallback()

    switch (error?.code) {
      case "auth/wrong-password":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message:
              "Invalid email or password. Please check if your credentials are correct before logging in again.",
          },
        })
        break
      case "auth/insufficient-privileges":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: (
              <span>
                Your account has insufficient privileges to access the system.
              </span>
            ),
          },
        })
        break
      case "auth/user-not-found":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: (
              <span>
                The email you entered does not match our records. Please{" "}
                <Link to="/verify-email">enroll</Link> to avail of our services
              </span>
            ),
          },
        })
        break

      default:
        setMessage({
          type: "danger",
          content: { code: error.code, message: error.message },
        })
    }
  }
}
