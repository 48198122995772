import { handleEmailLogin } from "./signin"
import { handleForgotPassword } from "./forgotPassword"
import {
  GATSBY_WEBSITE_URL,
  GATSBY_FIREBASE_SUDO_ROLE_ID,
} from "gatsby-env-variables"

export const handleAuthSubmit = async ({ payload, dispatch, location }) => {
  let { setLoading, setMessage } = payload

  switch (payload.module) {
    case "sign-in":
      handleEmailLogin({
        ...payload,
        callBack: () => {
          setLoading(false)
        },
        errorCallback: () => {
          setLoading(false)
        },
        dispatch,
        location,
      })
      break
    case "forgot-password":
      handleForgotPassword({
        ...payload,
        location,
        callback: () => {
          setLoading(false)
          setMessage({
            content:
              "Please check your email for instructions to reset your password.",
          })
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break

    default:
      break
  }
}

export const hasAdminRole = ({ enrollment }) => {
  return (
    enrollment?.subdomain === GATSBY_WEBSITE_URL &&
    enrollment?.status === "verified" &&
    enrollment?.role === GATSBY_FIREBASE_SUDO_ROLE_ID
  )
}
